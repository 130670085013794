<template>
  <BaseModal size="xl" title="Lịch sử điều chỉnh đề xuất chương trình xúc tiến đầu tư" :show="isPhuLucModalOpen"
             @update:show="value => toggleModal({show:value})">
    <template #default>
      <CRow>
        <CCol col="12">
          <CCard>
            <CCardHeader>
              <CIcon name="cil-grid"/>
              Lịch sử chương trình Xúc tiến đầu tư của Bộ, cơ quan ngang Bộ
            </CCardHeader>
            <CCardBody>
              <div class="position-relative table-responsive">
                <table class="table table-striped table-fixed table-hover table-bordered">
                  <thead>
                  <tr>
                    <th class="position-relative pr-4">STT</th>
                    <th class="position-relative pr-4">Số văn bản</th>
                    <th class="position-relative pr-4">Năm</th>
                    <th class="position-relative pr-4">Ngày chỉnh sửa</th>
                    <th class="position-relative pr-4">Thao tác</th>
                  </tr>
                  </thead>
                  <tbody class="position-relative">
                  <template v-if="historyData">
                    <tr v-for="(item, index) in historyData" :key="item.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.soVanBan }}</td>
                      <td>{{ item.nam_ChuongTrinh }}</td>
                      <!--                  <td>{{ item.tinhTrang }}</td>-->
                      <td>{{ formatDateData(item.modifiedDate) }}</td>
                      <td>
                        <CButton size="sm" color="info" class="mr-2" @click="viewItem(item)" title="Chi tiết">
                          <CIcon name="cil-options"/>
                        </CButton>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="historyData.length === 0">
                    <td colspan="8">
                      <slot name="no-items-view">
                        <div class="text-center my-5">
                          <h2>
                            Không có dữ liệu
                            <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                          </h2>
                        </div>
                      </slot>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <DeXuatCTXTDTBoNganhHistoryDetailModal :itemid="idprop"/>
    </template>
    <template #footer>
      <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
    </template>
  </BaseModal>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { displayLabel } from '@/shared/appConstants'
import { APPROVE_DEXUATCTXTDT, DELETE_DEXUATCTXTDT } from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import DeXuatCTXTDTBoNganhHistoryDetailModal from '@/views/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTBoNganhHistoryDetailModal'

export default {
  name: 'DeXuatCTXTDTBoNganhHistoryModal',
  props: ['item'],
  components: {
    // VueScrollingTable
    // FileUpload
    DeXuatCTXTDTBoNganhHistoryDetailModal
  },
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDT_BoNganh',
      isLoading: false,
      itemId: -1,
      idprop: -1
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDT', {
      // item: 'detailItem',
      isPhuLucModalOpen: 'isHistoryModalOpen',
      historyData: 'historyData',
      responseResult: 'responseResult',
      isDeleting: 'isDeleting',
      isApproving: 'isApproving'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDT', {
      // getListPaging: types.GET_LIST_DEXUATCTXTDT_PAGING,
      toggleHistoryDetailModal: types.TOGGLE_HISTORYDETAIL_MODAL,
      getListHistory: types.GET_HISTORY,
      deleteItem: DELETE_DEXUATCTXTDT,
      approveItem: APPROVE_DEXUATCTXTDT,
      toggleModal: types.TOGGLE_HISTORY_MODAL
    }),
    displayLabel,
    async fetchListItem (id) {
      await this.getListHistory(id)
    },
    viewItem (item) {
      // this.idprop = item.id
      // this.toggleHistoryDetailModal({ show: true })
      this.$router.push({ path: `de-xuat-ct-xtdt/${item.id}/chi-tiet-dieu-chinh` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.deletedItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.fetchListItem()
      }
    },
    formatDateData (v, format = 'YYYY-MM-DD H:i:s', outputFormat = 'DD/MM/YYYY') {
      if (v) {
        return moment(v, format).format(outputFormat)
      } else {
        return ''
      }
    }
  },
  async mounted () {
    await this.fetchListItem(this.itemId)
  },
  watch: {
    item: async function (val) {
      this.itemId = val.id
      await this.fetchListItem(this.itemId)
    }
  }
}
</script>

<style scoped>

</style>
