<template>
  <BaseModal size="xl" title="Lịch sử điều chỉnh đề xuất chương trình xúc tiến đầu tư" :show="isPhuLucModalOpen"
             @update:show="value => toggleModal({show:value})">
    <DeXuatCTXTDTDetail :itemid="itemid" />
  </BaseModal>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import DeXuatCTXTDTDetail from '@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTDetail'

export default {
  name: 'DeXuatCTXTDTBoNganhHistoryDetailModal',
  props: ['itemid'],
  components: {
    // FileUpload
    DeXuatCTXTDTDetail
  },
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDT_BoNganh',
      isLoading: false,
      idprop: -1
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDT', {
      // item: 'detailItem',
      isPhuLucModalOpen: 'isHistoryDetailModalOpen',
      historyData: 'historyData',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDT', {
      toggleModal: types.TOGGLE_HISTORYDETAIL_MODAL
    })
  },
  mounted () {
  },
  watch: {
    itemid: function (value) {
      console.log('History Detail:' + value)
    }
  }
}
</script>

<style scoped>

</style>
